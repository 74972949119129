<script>
import Layout from "../../layouts/main.vue";
import axios from "axios";
export default {
  components: { Layout },
  data() {
    return {
      schedule: [],
      doctor: [],
    };
  },
  methods: {
    async getSchedule() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/doctors/schedule/" +
          this.$route.params.id
      );
      this.schedule = response.data;
      console.log(this.schedule);
    },
    async getData() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/doctor/"
      );
      this.doctor = response.data;
      console.log(this.doctor);
    },
    async updateAvailability() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("doctors", JSON.stringify(this.schedule.doctors));
      urlencoded.append("excludedDate", JSON.stringify([this.schedule.excludedDate]));
      urlencoded.append("day", JSON.stringify([this.schedule.day]));
      urlencoded.append("startTime", this.schedule.startTime);
      urlencoded.append("endTime", this.schedule.endTime);
      urlencoded.append("consultingTime", this.schedule.consultingTime);
      urlencoded.append("status", this.schedule.status);

      await axios.put(
        "https://api.doctosoft.com/index.php/api/doctors/schedule/" +
          this.$route.params.id,
        urlencoded
      );

      this.$router.push("/admin/availability");
    // let docday = JSON.stringify(this.schedule.day)
    // console.log(docday)
    },
  },
  created() {
    this.getSchedule(), this.getData();
  },
};
</script>
<template>
  <Layout>
    <form class="needs-validation" @submit.prevent="updateAvailability">
      <div class="mb-3">
        <label for="doctors" class="form-label"
          >Doctors <span class="text-danger">*</span></label
        >
        <select
          class="form-select"
          multiple
          aria-label="multiple select example"
          v-model="schedule.doctors"
        >
          <option :value="doc.id" v-for="doc in doctor" :key="doc.id">
            {{ doc.name }}
          </option>
          <!-- <option>{{ schedule.doctors }}</option> -->
        </select>
      </div>
      <div class="mb-3">
        <label for="excludedDate" class="form-label"
          >Excluded Date <span class="text-danger">(optional)</span></label
        >
        <input
          type="text"
          name="excludedDate"
          id="excludedDate"
          class="form-control"
          placeholder="Excluded Date"
          v-model="schedule.excludedDate"
        />
      </div>
      <div class="mb-3">
        <label for="day" class="form-label"
          >Day <span class="text-danger">*</span></label
        >
        <input
          type="text"
          name="day"
          id="day"
          class="form-control"
          placeholder="Day"
          v-model="schedule.day"
        />
      </div>
      <div class="mb-3">
        <label for="startTime" class="form-label"
          >Start Time <span class="text-danger">*</span></label
        >
        <input
          type="text"
          name="startTime"
          id="startTime"
          class="form-control"
          placeholder="Start Time"
          v-model="schedule.startTime"
        />
      </div>
      <div class="mb-3">
        <label for="endTime" class="form-label"
          >End Time <span class="text-danger">*</span></label
        >
        <input
          type="text"
          name="endTime"
          id="endTime"
          class="form-control"
          placeholder="End Time"
          v-model="schedule.endTime"
        />
      </div>
      <div class="mb-3">
        <label for="consultingTime" class="form-label"
          >Consulting Time <span class="text-danger">*</span></label
        >
        <input
          type="text"
          name="consultingTime"
          id="consultingTime"
          class="form-control"
          placeholder="Consulting Time"
          v-model="schedule.consultingTime"
        />
      </div>
      <div class="mb-3">
        <label for="statusAvailability" class="form-label"
          >Status <span class="text-danger">*</span></label
        >
        <input
          name="statusAvailability"
          id="statusAvailability"
          type="text"
          class="form-control"
          placeholder="Enter status"
          v-model="schedule.status"
        />
      </div>

      <div class="mt-4">
        <b-button variant="success" class="w-100" type="submit">Add</b-button>
      </div>
    </form>
  </Layout>
</template>
<style></style>
